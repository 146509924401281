// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-service-js": () => import("/opt/build/repo/site/src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("/opt/build/repo/site/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oferta-js": () => import("/opt/build/repo/site/src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("/opt/build/repo/site/src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/site/.cache/data.json")

